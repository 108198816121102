multiple-select {
  .ion-close:before,
  .ion-close-circled:before,
  .ion-close-round:before,
  .ion-closed-captioning:before,
  .ion-close:before {
    content: "\f12a";
  }

  .ion-close-circled:before {
    content: "\f128";
  }

  .ion-close-round:before {
    content: "\f129";
  }

  .ion-closed-captioning:before {
    content: "\f317";
  }

  .mrg-top50 {
    margin-top: 50px;
  }

  .list-inline {
    padding-left: 0;
    list-style: none;
    margin-left: -5px;
  }

  .list-inline > li {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }

  .list-inline > li {
    padding-left: 7px;
    padding-right: 7px;
  }

  .candidate-info-wrap .list-inline li {
    vertical-align: middle;
  }

  .candidate-info-wrap .list-inline li.align-super {
    vertical-align: super;
  }

  .candidate-info-wrap .list-inline li.align-middle {
    vertical-align: middle;
  }

  .autocomplete-list {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    position: absolute;
    width: 100%;
    width: calc(100% + 3px);
    left: -1px;
    z-index: 100;
  }

  .autocomplete-list ul {
    margin: 0;
    padding: 0;
    max-height: 300px;
    overflow: auto;
  }

  .autocomplete-list ul li {
    cursor: pointer;
    list-style: inside none none;
    padding: 5px 0 5px 10px;
  }

  .autocomplete-list ul li.autocomplete-active {
    background-color: #23a9e1;
  }

  .autocomplete-active {
    background-color: #0000cc;
    color: #FFFFFF;
  }

  .form-item-container {
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    color: #555555;
    padding: 5px 10px;
    padding-left: 5px;
    padding-top: 0px;
    padding-bottom: 0px;
    position: relative;
  }

  .form-item-container .list-inline {
    margin-bottom: 0;
    height: 32px;
    cursor: text;
  }

  .form-item-container .list-inline li:not(.new-option) {
    background-clip: padding-box;
    background-color: #eeeeee;
    color: #333333;
    cursor: pointer;
    line-height: 12px;
    margin: 6px 0 3px 5px;
    padding: 3px 5px 3px 5px;
    position: relative;
    border: 1px solid #cccccc;
    border-radius: 4px;
  }
  .form-item-container .list-inline li.new-option {
    height: 25px;
    line-height: 25px;
    padding-top: 5px;
    vertical-align: top;
  }

  .form-item-container .list-inline li span {
  }

  .form-item-container .list-inline li span.remove {
    border: none;
    cursor: pointer;
    padding-left: 5px;
    padding-top: 0px;
    color: grey;
  }

  .form-item-container .list-inline li span.remove i {
    float: none;
    font-size: 12px;
  }

  .form-item-container .list-inline input {
    border: 1px solid #ccc;
    padding: 0;
    border: none;
    width: 25px;
    background: none;
    height: 20px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .error-msg {
    background-color: #ffdaca;
    color: #ed1c24;
    padding: 8px 10px;
  }

  .has-error .help-block,
  .has-error .control-label,
  .has-error .radio,
  .has-error .checkbox,
  .has-error .radio-inline,
  .has-error .checkbox-inline,
  .has-error.radio label,
  .has-error.checkbox label,
  .has-error.radio-inline label,
  .has-error.checkbox-inline label {
    color: #ef4136;
  }

  .has-error .form-control {
    background-color: #ffffff;
    border-color: #ef4136;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  .has-error .form-control:focus {
    border-color: #1cba7d;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .has-error .input-group-addon {
    color: #ef4136;
    border-color: #ef4136;
    background-color: #f2dede;
  }

  .has-error .form-control-feedback {
    color: #ef4136;
  }

  .has-success .help-block,
  .has-success .control-label,
  .has-success .radio,
  .has-success .checkbox,
  .has-success .radio-inline,
  .has-success .checkbox-inline,
  .has-success.radio label,
  .has-success.checkbox label,
  .has-success.radio-inline label,
  .has-success.checkbox-inline label {
    color: #3c763d;
  }

  .has-success .form-control {
    background-color: #cce5cc;
    border-color: #3c763d;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  .has-success .form-control:focus {
    border-color: #1cba7d;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .has-success .input-group-addon {
    color: #3c763d;
    border-color: #3c763d;
    background-color: #dff0d8;
  }

  .has-success .form-control-feedback {
    color: #3c763d;
  }

  &[disabled] {
    .form-item-container, .form-item-container .list-inline {
      background-color: #F7F7F8;
      border-color: #F7F7F8;
      cursor: default;
    }

    .form-item-container .list-inline li {
      background-color: #F7F7F8;
      border-color: #e8e8e9;
      cursor: default;
    }
  }


}