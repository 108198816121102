.nav {
  .username {
    margin-left: 10px;
  }

  i.offline {
    color: #ff2000 !important;
    animation: blinker 0.7s cubic-bezier(.5, 0, 1, 1) infinite alternate;
  }

  @keyframes blinker {
    to {
      opacity: 0.3;
    }
  }

  notification-icon .angular-notifications-icon {
    background-color: white !important;
    color: black;
  }

  notification-icon.success .angular-notifications-icon {
    background: #5A8770 !important;
    color: white !important;
  }

  notification-icon.fail .angular-notifications-icon {
    background: #DA4F4A !important;
    color: white !important;
  }

  .pending-task {
    padding: 3px;
  }

  .pending-task .text-grey {
    line-height: 20px;
  }

  li.pending {
    max-height: 400px;
    overflow-y: hidden;
  }

}

.navbar-brand {
  img {
    height: 50px;
    margin-top: 5px;
  }
}

.navbar .navbar-header .sidebar-mobile-toggler:hover {
  background-color: #da350a !important;
}

.navbar .navbar-right > li {
  padding: 0;
}

.navbar .navbar-collapse {
  background-color: #037cc6 !important;
}

.app-navbar-fixed .navbar .navbar-header {
  transition: height 0.5s;
}
@media (min-width: $screen-md-min) {
.app-navbar-fixed .navbar .navbar-header {
  height: 120px;

  .navbar-brand {
    img {
        height: 100px;
        padding-top: 15px;
        padding-left: 10px;
      }
    }
  }
}

.navbar .navbar-collapse .navbar-right > li > a {
  color: white;
}

.navbar .navbar-header {
  background-color: #242b36 !important;
}