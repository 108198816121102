[dw-loading] {
    position: relative;
}

.dw-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    display: none;
}

.dw-loading:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

.dw-loading.dw-loading-active {
    display: block;
}

.dw-loading.dw-loading-overlay {
    background-color: rgba(255, 255, 255, .7);
    z-index: 9999;
}

.dw-loading > .dw-loading-body {
    display: inline-block;
    vertical-align: middle;
    position: relative;
}

.dw-loading > .dw-loading-body > .dw-loading-spinner {
    position: relative;
}

.dw-loading > .dw-loading-body > .dw-loading-text {
    position: relative;
    top: 25px;
    font-weight: bold;
    font-size: 11px;
    text-shadow: 0 0 2px rgb(255, 255, 255);
}