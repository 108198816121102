.cursor-pointer {
  cursor: pointer;
}

.uib-left:before {
  content: "<";
}
.uib-right:before {
  content: ">";
}

.btn-red[disabled] {
  background-color: #e37874;
  border-color: #e37874;
  color: #fff;
}

.btn-red {
  background-color: #C82E29;
  border-color: #C82E29;
  color: #fff;
}

.btn-red:hover {
  background-color: #da350a !important;
  border-color: #C82E29;
  color: #fff;
}

.form-control::-moz-placeholder {
  color: $placeHolderColor;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: $placeHolderColor;
  opacity: 1;
}

.form-control::-webkit-input-placeholder {
  color: $placeHolderColor;
  opacity: 1;
}

.btn-wide-md {
  @media (min-width: $screen-md-min) {
    min-width: 120px;

    i {
      padding-right: 5px;
    }
  }
}

input[disabled]:not([type="submit"]), select[disabled] {
  background: #f7f7f8 !important;
  border-color: #F8F8F8 !important;
}

select.fa-options {
  font-family: 'FontAwesome', "Lato", sans-serif !important;
  font-size: 18px !important;
  text-align: center;
}

.input-group-addon[disabled] {
  background: #f7f7f8 !important;
  border-color: #F8F8F8 !important;
}

textarea.form-control {
  height: auto;
  overflow: hidden;
  resize: vertical;
  overflow-y: visible;

  &:disabled {
    color: black;
    background: #f7f7f8 !important;
    border-color: #F8F8F8 !important;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.btn-info.active, .btn-info.active:focus {
  span {
    color: white !important;
  }
}

.dropdown-menu {
  strong {
    font-weight: bold;
  }
  .active > a, a:hover {
    background-color: #e4e4e7 !important;
    color: #000000 !important;
  }


}

.uib-tab.add{
  .nav-link {
    padding-top: 9px;
  }

  button {
    margin-top: -8px;
  }
}

.panel-variant {
  .tab-pane {
    padding-left: 40px;
    padding-right: 40px;
  }
}

form {
  .invalid {
    color: red;
    padding-top: 3px;
    position: absolute;
  }
}

.nv-file-over {
  border: dotted 3px green;
}