.switch {
  cursor: pointer;
  background: #fff;
  border: 1px solid #dfdfdf;
  position: relative;
  display: inline-block;
  box-sizing: content-box;
  overflow: visible;
  width: 33px;
  height: 20px;
  padding: 0px;
  margin: 0px;
  border-radius: 20px;
  cursor: pointer;
  box-shadow: rgb(223, 223, 223) 0px 0px 0px 0px inset;
  transition: 0.3s ease-out all;
  -webkit-transition: 0.3s ease-out all;
  top: -1px;
}
/*adding a wide width for larger switch text*/
.switch.wide {
  width:80px;
}
.switch small {
  background: #fff;
  border-radius: 100%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.4);
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0px;
  left: 0px;
  transition: 0.3s ease-out all;
  -webkit-transition: 0.3s ease-out all;
}
.switch.checked {
  background: rgb(100, 189, 99);
  border-color: rgb(100, 189, 99);
}
.switch.checked small {
  left: 13px;
}
/*wider switch text moves small further to the right*/
.switch.wide.checked small {
  left:32px;
}
/*styles for switch-text*/
.switch .switch-text {
  font-family:Arial, Helvetica, sans-serif;
  font-size:13px;
}

.switch .off {
  display:block;
  position: absolute;
  right: 10%;
  top: 25%;
  z-index: 0;
  color:#A9A9A9;
}

.switch .on {
  display:none;
  z-index: 0;
  color:#fff;
  position: absolute;
  top: 25%;
  left: 9%;
}

.switch.checked .off {
  display:none;
}

.switch.checked .on {
  display:block;

}

.switch.disabled {
  opacity: .50;
  cursor: not-allowed;
}
