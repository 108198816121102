.btn-red[disabled] {
  background-color: rgba(186, 90, 70, 0.82) !important;
  border-color: #e37874;
  color: #fff;
}

.btn-red[disabled]:hover {
  background-color: rgba(186, 33, 17, 0.82) !important;
  border-color: #e37874;
  color: #fff;
}

.key-control-focus, input.key-control-focus, input.key-control-focus:disabled {
  background-color: rgba(41, 112, 199, 0.4) !important;
}

