nav {
 i.fa {
   color: #2C2F3B !important;
   min-width: 30px;
   text-align: center;
   font-size: 30px;
 }

  li.active i.fa {
    color: #C82E29 !important;;
  }

}
#sidebar .sidebar-container {
  background-color: #242b36 !important;
  margin-left: 0;
}

#sidebar > div nav {
  padding-top: 10px;

  ul.main-navigation-menu {
    padding-top: 50px;
  }

  li {
    padding: 0 !important;
  }

  .item-content {
    background-color: #1c2026 !important;
  }

  &:before {
    display: none;
  }
  &:after {
    display: none;
  }
}

#sidebar > div nav > ul > li {
  box-shadow: none !important;

  background-color: transparent !important;
  &:hover, &.active, &.active:hover {
    color: #687384;
  }

  &:hover, &.active {
    .item-content {
      background-color: #24427a !important;
      transition: background-color 0.5s;
    }
  }


  &.active {
    .item-inner, .item-media .fa:before {
      color: white !important;
    }
  }
}

.item-content .item-inner, .open .item-content .item-inner, li:last-child .item-content .item-inner {
  color: #687084;
}

li {
  margin: 2px 0 3px 0 !important;
}

.no-touch #sidebar > div nav > ul > li:hover > a {
  color: white;
}

#sidebar > div nav > ul {
  padding-top: 0;
  margin: 0;
  .fa {
    color: #687384 !important;
  }
}