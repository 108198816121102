@import "variables";
@import "common/common";
@import "angular-loading";
@import "angular-notification-icons";
@import "font-awesome";
@import "auth/auth";
@import "select";
@import "jquery.bootstrap-touchspin.min";

.keyTest {
  padding: 10px;
  border: 2px solid black;
}

.glyphicon {
  font-family: FontAwesome !important;
  display: none !important;
}

.alert-old {
  background-color: #e9e9e9;
  border-color: #cacaca;
  color: #383838;
}

.panel {
  background-color: #272c33;
  color: white;

  .panel-heading {
    background-color: #272c33;
    border-bottom: 1px solid #35393e;
    .over-title {
      color: white;
    }
  }

  .panel-footer {
    border-top: 1px solid #35393e;
  }
}