@media screen {
  @import "sidebar";
  @import "top-navbar";
  @import "home";

  .breadcrumb-wrapper {
    margin-bottom: 20px;
  }

  .panel-heading {
    .btn, .input-group {
      margin-top: -7px;
    }
  }
  .table > tbody > tr > td.right {
    text-align: right;
  }
  .table > thead > tr > th.right {
    text-align: right;
  }
}


