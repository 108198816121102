$placeHolderColor: #888888;

//  breakpoints

// Extra small screen / phone
$screen-xs-min: 480px !default;

// Small screen / tablet
$screen-sm-min: 768px !default;

// Medium screen / desktop
$screen-md-min: 992px !default;

// Large screen / wide desktop
$screen-lg-min: 1200px !default;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
