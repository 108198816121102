table.table {
  thead > tr > th {
    font-weight: bold;
  }

  thead > tr > th[ng-click] {
    cursor: pointer;
  }
}

.sort-btn {
  font-family: 'FontAwesome';
  &.icon-up:before {
    content: "\f0de";
  }
  &.icon-down:before {
    content: "\f0dd";
  }
}

.table-search {
  max-width: 30%;
}
