.angular-notifications-container {
  position: relative;
  display: table-cell;
  width: 100%;
}
.angular-notifications-icon.overlay {
  position: absolute;
  z-index: 1;
}
.angular-notifications-icon {
  right: -10px;
  top: -10px;
  border-radius: 50%;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 3px;
  min-width: 25px;
  height: 25px;
  background: red;
  color: white;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: default;
}
.angular-notifications-icon.wide-icon {
  border-radius: 30% / 50%;
  background-clip: padding-box;
}
.angular-notifications-icon.fade {
  -webkit-animation: notification-fade-in 0.5s;
  animation: notification-fade-in 0.5s;
}
.angular-notifications-icon.bounce {
  -webkit-animation: notification-bounce 0.5s;
  animation: notification-bounce 0.5s;
}
.angular-notifications-icon.grow {
  -webkit-animation: notification-grow 0.5s;
  animation: notification-grow 0.5s;
}
.angular-notifications-icon.shake {
  -webkit-animation: notification-shake 0.5s;
  animation: notification-shake 0.5s;
}
@-webkit-keyframes notification-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes notification-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes notification-bounce {
  30% {
    -webkit-transform: perspective(1px) translate(0, -8px);
    -ms-transform: perspective(1px) translate(0, -8px);
    transform: perspective(1px) translate(0, -8px);
  }
  60% {
    -webkit-transform: perspective(1px) translate(0, 0);
    -ms-transform: perspective(1px) translate(0, 0);
    transform: perspective(1px) translate(0, 0);
  }
  80% {
    -webkit-transform: perspective(1px) translate(0, -5px);
    -ms-transform: perspective(1px) translate(0, -5px);
    transform: perspective(1px) translate(0, -5px);
  }
  to {
    -webkit-transform: perspective(1px) translate(0, 0);
    -ms-transform: perspective(1px) translate(0, 0);
    transform: perspective(1px) translate(0, 0);
  }
}
@keyframes notification-bounce {
  30% {
    -webkit-transform: perspective(1px) translate(0, -8px);
    -ms-transform: perspective(1px) translate(0, -8px);
    transform: perspective(1px) translate(0, -8px);
  }
  60% {
    -webkit-transform: perspective(1px) translate(0, 0);
    -ms-transform: perspective(1px) translate(0, 0);
    transform: perspective(1px) translate(0, 0);
  }
  80% {
    -webkit-transform: perspective(1px) translate(0, -5px);
    -ms-transform: perspective(1px) translate(0, -5px);
    transform: perspective(1px) translate(0, -5px);
  }
  to {
    -webkit-transform: perspective(1px) translate(0, 0);
    -ms-transform: perspective(1px) translate(0, 0);
    transform: perspective(1px) translate(0, 0);
  }
}
@-webkit-keyframes notification-grow {
  30% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
  60% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes notification-grow {
  30% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
  60% {
    -webkit-transform: scale(0.8);
    -ms-transform: scale(0.8);
    transform: scale(0.8);
  }
  to {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes notification-shake {
  20% {
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  40% {
    -webkit-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
  60% {
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  to {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
}
@keyframes notification-shake {
  20% {
    -webkit-transform: rotate(20deg);
    -ms-transform: rotate(20deg);
    transform: rotate(20deg);
  }
  40% {
    -webkit-transform: rotate(-20deg);
    -ms-transform: rotate(-20deg);
    transform: rotate(-20deg);
  }
  60% {
    -webkit-transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    transform: rotate(10deg);
  }
  80% {
    -webkit-transform: rotate(-10deg);
    -ms-transform: rotate(-10deg);
    transform: rotate(-10deg);
  }
  to {
    -webkit-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
}
