.row.home {
  .panel-body {
    font-size: 25px;
    text-align: right;
  }

  .panel-body.refresh {
      text-align: center;
  }
}

.switch-table {
  width: 100%;

  td {
    padding: 5px;
  }
}